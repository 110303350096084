var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        justify: "center",
        value: _vm.value,
        persistent: "",
        "max-width": "300"
      }
    },
    [
      _c("v-card", { attrs: { color: "#fafafa" } }, [
        _c(
          "section",
          { staticClass: "ma-0 pa-6" },
          [
            _c(
              "v-row",
              { attrs: { justify: "center" } },
              [
                _c("v-icon", { attrs: { "x-large": "", color: "#2AACE3" } }, [
                  _vm._v("mdi-help-rhombus-outline")
                ])
              ],
              1
            ),
            _c("v-row", { attrs: { justify: "center" } }, [
              _c("h3", [_vm._v(" Change A2 Ready Status? ")])
            ]),
            _c(
              "v-row",
              { attrs: { justify: "center" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "elevation-1",
                    attrs: { height: "40" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("update:value", false)
                      }
                    }
                  },
                  [_vm._v(" No ")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "white--text ml-2",
                    attrs: { color: "#0c67a5", height: "40" },
                    on: { click: _vm.confirm }
                  },
                  [_vm._v("Yes ")]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }