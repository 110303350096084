<template>
  <v-dialog justify="center" :value="value" persistent max-width="300">
    <v-card color=#fafafa>
      <section class="ma-0 pa-6">
        <v-row justify="center">
          <v-icon x-large color="#2AACE3">mdi-help-rhombus-outline</v-icon>
        </v-row>
        <v-row justify="center">
          <h3>
            Change A2 Ready Status?
          </h3>
        </v-row>
        <v-row justify="center">
          <v-btn height="40" class="elevation-1" @click.stop="$emit('update:value', false)">
            No
          </v-btn>
          <v-btn color="#0c67a5" height="40" class="white--text ml-2"
            @click="confirm">Yes
          </v-btn>
        </v-row>
      </section>
    </v-card>
  </v-dialog>
</template>

<script>
import { notification } from '@/mixins/notification'

export default {
  name: 'A2ReadyChange',

  mixins: [notification],

  props: ['value', 'selectedAd', 'getAds'],

  data () {
    return {}
  },

  methods: {
    async confirm () {
      this.selectedAd.readyForUpload === null ? this.selectedAd.readyForUpload = 'Y' : this.selectedAd.readyForUpload = null
      await this.$ads.updateAd(this.selectedAd)
        .then(() => {
          this.notify('success', 'Ad Successfully Updated')
          this.$emit('update:value', false)
          this.getAds()
        }).catch(err => {
          this.notify('error', err)
        })
    }
  }
}
</script>
